.home {
  .home-text {
    margin: auto;
    width: 75%;
    text-align: center;
    padding: 50px 10px 20px 10px;
  }

  .home-container {
    .home-talleres {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;

      //This works well if there's only 3 ItemLongs ALWAYS. If there's more than  3 it breakes.
      @media (max-width: 1024px) {
        flex-wrap: nowrap;
      }

      @media (max-width: 640px) {
        flex-direction: column;
      }
    }
  }
}

.youtube-single {
  text-align: center;
  margin-top: 20px;
  display: block;
}
