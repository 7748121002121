.socials-ul {
  float: right;
  position: relative;
  left: 0;
  display: block;
  margin: 0 10px 0 0;

  @media (max-width: 1024px) {
    display: none;
  }
}

.socials-li {
  float: left;
  padding-left: 5px;
}

.socials-socicon {
  padding: 4px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
}
