.navbartop {
  background-color: #efc24e;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    background-color: white;
    color: black;
  }

  .navbartop-text {
    display: flex;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}

.navbartop-title {
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
}

.navbartop-subtitle {
  margin-left: 15px;
  font-size: 14px;
  flex-grow: 0;
}

.navbartop-user {
  display: flex;
  align-items: center;
  padding: 0;
}

.test {
  display: flex;
  align-items: center;
}

.navbartop-li {
  margin: 0px 5px;
  position: relative;
}

.navbartop-login {
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    display: none;
  }
}

.navbartop-link {
  color: rgba(255, 255, 255, 1);
  text-shadow: none;
  text-decoration: none;

  .account-badge {
    display: block;
    background-color: #b94a48;
    width: 25px;
    text-align: center;
    border-radius: 15px;
    margin-left: 5px;
    color: #fff;
  }

  .cart-badge {
    font-size: 11px;
    background-color: black;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 100%;
    vertical-align: initial;
    line-height: 20px;
    position: absolute;
    top: -8px;
    right: -8px;
  }
}

.navbartop-fa {
  background-color: transparent;
  color: white;
  font-size: 24px;
  margin: 0px 5px;
  @media (max-width: 1024px) {
    color: rgba(145, 125, 6, 1);
  }
}

.navbartop-signup {
  border: 1px solid #cc4998;
  background-color: #cc4998;
  color: #efc24e;
  padding: 5px 8px;
  border-radius: 25px;
  margin-left: 5px;

  @media (max-width: 1024px) {
    display: none;
  }
}
