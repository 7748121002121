.carddetails-container {
  background-color: white;
  width: 65%;
  margin: 20px auto;
  box-shadow: 1px 1px 5px #ccc;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 90%;
    padding: 10px;
  }

  .innercard-container {
    display: flex;
    align-items: flex-start;

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    div:nth-child(1) {
      position: relative;

      .carddetails-discount {
        background-color: var(--accent);
        text-transform: uppercase;
        color: white;
        padding: 5px 15px;

        position: absolute;
        top: 0;
        right: 0;
      }
      .carddetails-image {
        width: 250px;
        height: 250px;
        @media (max-width: 640px) {
          width: 100%;
          height: 100%;
          margin-bottom: 15px;
        }
      }
      .social-icons {
        a {
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
        }
      }

      .itemlong-fav-container {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        margin: 10px auto;
        background-color: transparent;
        border: none;

        &:hover .fa-heart {
          color: red;
        }

        .fa-heart {
          margin-right: 5px;
          font-size: 14px;
        }

        span {
          display: block;
          font-size: 12px;
        }
      }
      .faved {
        .fa-heart {
          color: red;
        }
      }
    }

    .carddetails-text {
      &.buying {
        color: var(--text);
        margin-left: 25px;

        @media (max-width: 767px) {
          margin: 0;
          text-align: center;
        }

        span {
          text-transform: uppercase;
        }

        .carddetails-price-container {
          display: flex;
          margin: 10px 0px;
          font-size: 16px;
          align-items: flex-end;

          @media (max-width: 767px) {
            justify-content: center;
          }

          div {
            margin-right: 10px;
          }

          .carddetails-price {
            text-decoration: line-through;
            font-size: 14px;
          }
          .carddetails-price-discount {
            font-weight: bold;
            font-size: 18px;
            span:nth-child(2) {
              margin-left: 5px;
              font-size: 14px;
            }
          }
        }

        .carddetails-buy-btn {
          height: 45px;
          line-height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 55px;
          border-radius: 20px;
          background-color: rgba(191, 0, 0, 1);
          color: #fff;
          margin-top: 25px;
          border: none;
          font-size: 14px;
          cursor: pointer;
          transition: filter ease;

          @media (max-width: 767px) {
            margin: 20px auto;
          }

          &:hover {
            filter: brightness(1.1);
          }

          i {
            margin-right: 10px;
          }
        }
      }
      h4 {
        font-size: 32px;
        margin: 0px 10px;

        @media (max-width: 640px) {
          font-size: 24px;
        }
      }

      > span {
        display: block;
        margin-bottom: 10px;
        font-size: 16px;
      }
    }
  }

  .carddetails-content {
    word-break: break-word;
    overflow: hidden;
  }

  .social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    li {
      margin-right: 10px;
      background-color: black;
      color: white;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      vertical-align: middle;
      text-align: center;
      line-height: 50px;
      font-size: 24px;
      cursor: pointer;

      @media (max-width: 640px) {
        font-size: 16px;
        width: 35px;
        height: 35px;
        line-height: 35px;
      }
    }
  }
}
