:root {
  --accent: rgba(191, 0, 0, 1);
  --primary: rgba(216, 144, 22, 1);
  --secondary: rgb(184, 130, 37);
  --borders: rgb(170, 168, 168);
  --background: rgba(255, 255, 255, 0.85);
  --borders: rgba(227, 227, 227, 1);
}

.label {
  display: flex;
  flex-direction: column;
  margin: 5px 0px;
  font-size: 14px;

  position: relative;

  .input {
    border: 1px solid var(--borders);
    height: 25px;
    border-radius: 25px;
    width: 100%;

    &.inputErrorMsg {
      border-radius: 0;
      border: 1px solid #b94a48;
    }

    &.inputIncomplete {
      border: 1px solid #b94a48;
    }

    &:focus {
      border-color: var(--secondary);
      box-shadow: 0px 0px 5px var(--accent);
      outline: none;

      &.inputCompleted {
        border-color: var(--completedInput);
        box-shadow: 0px 0px 5px var(--completedInput);
        outline: none;
      }

      &.whatsappModal {
        border-color: #29a71a;
      }
    }
  }
}
