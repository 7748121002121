.file-uploader {
  width: 300px;
  height: 150px;
  border: 2px solid steelblue;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
}

.file-uploader--dragging {
  background-color: lightblue;
}

.file-uploader__contents {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.file-uploader__file-name {
  font-weight: 700;
}

.file-uploader__input {
  display: none;
}
