.navbar {
  box-shadow: 0 10px 10px rgb(0 0 0 / 30%);
  display: block;
  background-color: white;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: space-between;
  }

  .navbar-burgericon {
    color: rgba(145, 125, 6, 1);
    font-size: 30px;
    padding: 10px;
    z-index: 1;
    cursor: pointer;
    @media (min-width: 1025px) {
      display: none;
    }
  }
}

.app-overlay {
  background: black;
  height: 100vh;
  width: 100%;
  transition: opacity 300ms, visibility 300ms ease-in;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: hidden;
}

.overlay-opacity-on {
  opacity: 0.5;
}

.overlay-opacity-off {
  opacity: 0;
  visibility: hidden;
}
