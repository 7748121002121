.redbutton-container {
  text-align: center;
  margin-top: 20px;
  display: block;
}

.redbutton {
  line-height: 44px;
  height: 44px;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 1);
  background-color: rgba(191, 0, 0, 1);
  margin-top: 10px;
  border-radius: 25px;
  background-image: none;
  text-shadow: none;
  box-shadow: none;
  padding: 0px 18px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}
