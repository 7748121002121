.item-container {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item-li {
  border-top: 0.5px solid rgba(0, 0, 0, 0.541);
}

.item-a {
  padding: 15px;
  display: inline-block;
  width: fit-content;
  position: relative;
  color: black;
  text-decoration: none;
}

.internal-drawer {
  .link-container {
    position: relative;

    .sublevel {
      cursor: pointer;
    }

    .sublevel::after {
      position: absolute;
      top: 45%;
      content: "";
      right: 5%;
      width: 0.4em;
      height: 0.4em;
      border-right: 0.2em solid black;
      border-top: 0.2em solid black;
      transform: rotate(45deg);
      margin-right: 0.5em;
    }

    .sublevel::before {
      border-right: 0.5px solid black;
      content: "";
      position: absolute;
      height: 100%;
      right: 20%;
      top: 0;
    }
  }
}
