.card-btn-container {
  text-align: left;
}

.card-btn {
  background-color: transparent;
  border: 1px solid rgba(248, 144, 35, 1);
  color: rgba(248, 144, 35, 1);
  font-size: 14px;

  &:hover {
    color: var(--accent);
    border-color: var(--accent);
    text-decoration: underline;
  }

  span {
    color: inherit;
  }
}

.card-btn-icon {
  margin-left: 10px;
}

.card-container {
  display: flex;
  align-items: flex-start;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.78);
  padding: 20px;
  max-width: 65%;
  margin: 30px auto;

  @media (max-width: 1024px) {
    max-width: 90%;
    align-items: center;
    border: 1px solid red;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    max-width: 80%;
    border: 1px solid #000;
  }

  .card-image {
    height: 250px;
    width: 250px;

    @media (max-width: 1024px) {
      display: block;
      max-width: 35%;
      min-width: 250px;
    }

    @media (max-width: 767px) {
      display: block;
      max-width: 75%;
      margin: 10px auto;
      min-width: 250px;
    }
  }

  .card-text {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    .link {
      text-decoration: none;
      color: rgb(248, 113, 35);

      &:hover {
        color: var(--accent);
        text-decoration: underline;
      }

      &:visited {
        color: rgba(248, 144, 35, 1);
      }
      h4 {
        font-size: 17.5px;
        margin: 10px 0;
      }
    }

    .title {
      &:hover {
        color: var(--accent);
        text-decoration: underline;
      }
    }
  }
}
