:root {
  --accent: rgba(191, 0, 0, 1);
  --primary: rgba(216, 144, 22, 1);
}

.fslightbox-source {
  border: 10px solid white;
  border-radius: 5px;
}

.photocard {
  margin: 15px;
  background-color: white;
  border: 1px solid var(--primary);
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  color: var(--primary);

  &:hover {
    text-decoration: underline;
    color: var(--accent);
  }

  .photocard-image-container {
    background: rgb(255, 255, 255);
    transition: border 300ms ease-in-out;
    padding: 5px;

    &:hover {
      border-color: var(--accent);
    }

    .photocard-image {
      object-fit: cover; //To avoid img distortion
      width: 100%;
      height: 150px;
    }
  }
  .photocard-title {
    text-align: center;
    padding: 5px;
    max-width: 220px;
    margin: 5px auto;
  }
}
