.delivery-container {
  background-color: var(--background);
  border: 1px solid var(--borders);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 60%;
  border-top: none;
  margin: 10px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 10px auto;
  }

  .delivery-title {
    padding: 10px;
    font-weight: bold;
    border-bottom: 1px solid var(--borders);
    border-top: 1px solid var(--borders);

    .fa-home {
      margin-right: 10px;
    }
  }
  form {
    display: flex;
    flex-direction: column;

    .delivery-row01-container {
      margin-top: 10px;
      padding: 10px;

      label {
        display: flex;
        flex-direction: column;
      }
    }

    .delivery-row02-container {
      padding: 10px;
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      label {
        display: flex;
        flex-direction: column;
      }
    }

    .delivery-row03-container {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }

      > label {
        display: flex;
        flex-direction: column;
      }

      .delivery-row03-adress {
        label {
          display: flex;
          flex-direction: column;
        }
      }

      > label {
        display: flex;
        flex-direction: column;
      }
    }

    .delivery-checkbox {
      margin: 10px 0;

      input {
        margin-right: 10px;
      }
    }

    .padding {
      padding: 10px;
    }

    .delivery-row04-container {
      div {
        padding: 10px;
      }
      label:nth-child(2) {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        textarea {
          border-radius: 25px;
          margin-top: 10px;
          border: 1px solid var(--borders);
        }
      }
    }
  }

  p {
    padding: 0 10px;
  }

  .delivery-buttons {
    display: flex;
    border-top: 1px solid var(--borders);
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    button {
      height: 45px;
      border: 1px solid #b3b3b3;
      text-align: center;
      width: 100%;
      border-radius: 25px;
      margin: 0px 3px;
      cursor: pointer;
      font-size: 14px;
    }

    .shiptype-left-btn {
      background-color: #f5f5f5;
      i {
        margin-right: 5px;
      }
      &:hover {
        background-color: #e6e6e6;
      }
    }
    .shiptype-right-btn {
      background-color: rgba(216, 144, 22, 1);
      color: #fff;
      i {
        margin-left: 5px;
      }
      &:hover {
        background-color: rgba(222, 0, 0, 1);
      }
    }
  }
}

.delivery-number {
  width: 220px;
}
