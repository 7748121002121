:root {
  --accent: rgba(191, 0, 0, 1);
  --primary: rgba(216, 144, 22, 1);
  --secondary: rgb(184, 130, 37);
  --borders: rgb(170, 168, 168);
  --background: rgba(255, 255, 255, 0.7);
  --borders: rgba(227, 227, 227, 1);
  --completedInput: rgba(82, 168, 236, 0.8);
  --text: rgba(145, 125, 6);
}

.app {
  background-color: rgba(252, 243, 224, 1);
  background-image: url(https://www.karinazarfino.com/datos/uploads/mod_diseno/bg2-5ee912ddee69f.png);
  background-repeat: repeat;
  background-attachment: scroll;
  position: relative;

  /*Should check if this works properly in all pages*/
}

ul {
  list-style: none;
}

li {
  list-style: none;
}

/*MODAL GLOBAL STYLES*/
/*THIS IS HERE 'CAUSE IT'S USED BY CART AND WHATSAPPBTN*/

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.45);
}

.Modal {
  position: absolute;
  top: "50%";
  left: "50%";
  right: "auto";
  bottom: "auto";
  margin-right: "-50%";
  transform: "translate(-50%, -50%)";
  outline: none;
  border: none;
}
