.buydetails-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 14px;
  padding: 0px 25px;
  margin: 25px;

  @media (max-width: 760px) {
    flex-direction: column;
    padding: 0px;
    margin: 10px 0px;
    width: 100%;
  }

  .buydetails-table-container {
    background-color: rgba(255, 255, 255, 0.85);
    border: 1px solid rgba(153, 153, 153, 0.3);
    width: 50%;
    margin-right: 20px;
    max-width: 750px;
    @media (max-width: 1024px) {
      width: 95%;
      margin: 0 auto;
    }
    table {
      border-collapse: collapse;
      width: 100%;

      th {
        padding: 10px;
        display: table-cell;

        @media (max-width: 1024px) {
          padding: 5px;
        }
      }

      td {
        font-size: 14px;
        padding: 10px 5px;
      }

      thead {
        border-bottom: 1px solid rgba(153, 153, 153, 0.3);
      }
    }

    .buydetails-first-table {
      .buydetail-state {
        font-weight: bold;

        i {
          margin-right: 5px;
        }

        & td:nth-child(2) {
          text-align: right;
        }
      }
    }

    .buydetails-second-table {
      .detail-article {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          width: 75px;

          @media (max-width: 1024px) {
            display: none;
          }
        }

        .item-title {
          flex-grow: 1;
          word-wrap: break-word;
          margin-bottom: 5px;
          display: block;
          font-size: 14px;
        }

        .item-code {
          font-size: 12px;
        }

        span {
          font-weight: bold;
          display: inline-block;
        }
      }
    }
  }

  .buydetails-userinformation {
    width: 25%;
    @media (max-width: 1024px) {
      width: 100%;
    }
    .wrapper {
      background-color: rgba(255, 255, 255, 0.85);
      border: 1px solid rgba(153, 153, 153, 0.3);

      max-width: 350px;
      overflow: auto;
      word-break: break-all;
      margin-bottom: 15px;

      @media (max-width: 1024px) {
        margin: 10px 0px;
      }
    }

    .userdata-container {
      div {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(153, 153, 153, 0.3);
      }

      div:nth-child(2) {
        padding: 10px 15px;

        span:nth-child(1) {
          font-weight: bold;
          margin-right: 5px;
        }
      }
      .username-div {
        padding: 10px 15px;
        i {
          margin-right: 5px;
          font-size: 14px;
        }
        p {
          margin: 0;
        }
      }
    }

    .delivery-address-container {
      // width: 350px;
      p {
        border-bottom: 1px solid rgba(153, 153, 153, 0.3);
        padding: 10px 15px;
        margin: 0;
        font-weight: bold;
      }

      .delivery-information {
        padding: 5px 15px;

        div {
          margin: 5px 0;
        }
      }

      span {
        margin: 5px 0;
      }
    }
  }
}

//Helpers

.bold {
  font-weight: bold;
}

.text-right {
  text-align: right;
}

.text-start {
  text-align: start;
}

.border-bottom {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}

.purchase-notes {
  & > div {
    padding: 10px;

    div {
      word-break: break-word;
    }

    p {
      margin: 10px 0px;
    }
  }
}

.btn-container {
  padding: 10px;
  .btn-shopping {
    border: 1px solid rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    color: #333;

    &:hover {
      background-color: #e6e6e6;
    }
  }

  button {
    width: 200px;
    height: 45px;
    border-radius: 25px;
    border: none;
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;

    .fa {
      font-size: 12px;
    }

    .fa-chevron-right {
      margin-left: 5px;
    }

    .fa-chevron-left {
      margin-right: 5px;
    }
  }
}
