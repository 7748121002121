.itemlong {
  // width: 20%;
  // display: list-item;
  // padding: 10px;
  // margin: 0px 25px;

  width: 300px;
  display: flex;
  padding: 10px;
  margin: 0 25px;
  // height: 400px;

  @media (max-width: 1025px) {
    width: 33%;
    margin: 0 auto;
  }

  @media (max-width: 640px) {
    width: 90%;
  }

  .itemlong-card {
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    background-color: hsla(0, 0%, 100%, 0.78);
    color: #363636;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 20%);
    min-height: 30px;
    padding: 10px;
    position: relative;
    overflow: hidden;

    .itemlong-card-discount {
      display: block;
      color: rgba(255, 255, 255, 1);
      background-color: #950500;
      position: absolute;
      font-size: 14px;
      line-height: 20px;
      padding: 2px 10px;
      text-align: center;
      height: 20px;
      top: 20px;
      right: 20px;
    }

    .itemlong-img {
      background-color: transparent;
      margin: 5px auto;
      padding-bottom: 15px;
      display: block;
      width: 250px;
      height: 250px;
    }

    .itemlong-title-link {
      text-decoration: none;
      .itemlong-title {
        line-height: 17px;
        font-size: 16px;
        color: #f89023;
        text-align: center;
        margin: 0;

        &:hover {
          color: var(--accent);
        }
      }

      &:hover {
        text-decoration: underline;
        color: var(--accent);
      }
    }

    .itemlong-text {
      line-height: 17px;
      font-size: 16px;
      color: #75640c;
      text-align: center;
      margin: 15px 0px;
      word-break: break-word;
    }

    .itemlong-price {
      line-height: 15px;
      color: rgba(54, 54, 54, 1);
      margin-bottom: 20px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;

      .itemlong-oldprice {
        font-size: 14px;
        margin-right: 10px;
        font-weight: normal;
        text-decoration: line-through;
      }

      .itemlong-currencycode {
        font-size: 10px;
      }
    }

    .itemlong-buy-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .itemlong-btn-container {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        width: 275px;
        margin: 0 auto;

        .input-btn {
          border: 1px solid #ccc;
          height: 45px;
          width: 55px;
          background-color: #f5f5f5;
          border: 1px solid #b3b3b3;
          cursor: pointer;

          &:hover {
            background-color: #e6e6e6;
          }
        }

        input {
          border: 1px solid #b3b3b3;
          width: 125px;
          text-align: center;
          font-size: 14px;
        }

        .plus {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
        .minus {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
      .itemlong-buy-btn {
        height: 45px;
        line-height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 55px;
        border-radius: 20px;
        margin: 10px auto;
        background-color: rgba(191, 0, 0, 1);
        color: #fff;
        border: none;
        font-size: 14px;
        cursor: pointer;
        transition: filter ease;

        &:hover {
          filter: brightness(1.1);
        }

        i {
          margin-right: 10px;
        }
      }
    }
    .itemlong-fav-container {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      margin: 0 auto;
      background-color: transparent;
      border: none;

      &:hover .fa-heart {
        color: red;
      }

      .fa-heart {
        margin-right: 5px;
        font-size: 14px;
      }

      span {
        display: block;
        font-size: 12px;
      }
    }
    .faved {
      .fa-heart {
        color: red;
      }
    }
  }
}

.fa-spinner {
  animation: spinner 1.3s infinite linear;
  margin-right: 5px;
}

.span-timeout {
  color: rgba(0, 0, 0, 0.35);
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
