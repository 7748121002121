:root {
  --accent: rgba(191, 0, 0, 1);
  --primary: rgba(216, 144, 22, 1);
  --secondary: rgb(184, 130, 37);
  --borders: rgb(170, 168, 168);
  --background: rgba(255, 255, 255, 0.85);
  --borders: rgba(227, 227, 227, 1);
}

.tr-discount {
  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
  }
}

.cart-container {
  width: 95%;
  margin: 20px auto;
  background-color: rgba(255, 255, 255, 0.85);
  border: 1px solid rgba(0, 0, 0, 0.2);

  @media (max-width: 640px) {
    width: 100%;
  }

  .form-class {
    table {
      border-collapse: collapse;
      width: 100%;

      @media (max-width: 640px) {
        display: flex;
      }

      .cart-table-head {
        @media (max-width: 640px) {
          display: none;
        }
      }

      th {
        border-bottom: 1px solid rgba(153, 153, 153, 0.3);
        text-align: left;
        padding: 10px;
        display: table-cell;
      }

      td {
        font-size: 13px;
        padding: 10px;
      }

      thead {
        border-bottom: 1px solid rgba(153, 153, 153, 0.3);
      }

      .cart-table-body {
        .cart-table-primary {
          border-bottom: 1px solid rgba(153, 153, 153, 0.3);

          @media (max-width: 640px) {
            display: flex;
            flex-direction: column;
          }
        }

        .cart-table-footer {
          border-bottom: 1px solid rgba(153, 153, 153, 0.3);
        }
        .cart-article {
          display: flex;
          justify-content: space-evenly;
          align-items: center;

          @media (max-width: 640px) {
            display: flex;

            align-items: flex-start;
          }

          img {
            width: 125px;
            height: 125px;
            margin-right: 10px;
            @media (max-width: 640px) {
              width: 70px;
              height: 70px;
              margin-right: 10px;
            }
          }

          a {
            flex-grow: 1;
            word-break: break-word;
            color: rgba(216, 144, 22, 1);
            text-decoration: none;
            margin-top: 5px;

            &:hover {
              color: rgba(222, 0, 0, 1);
              text-decoration: underline;
            }
          }
        }
        .cart-quantity {
          text-align: center;

          @media (max-width: 640px) {
            text-align: left;
          }

          input {
            height: 31px;
            text-align: center;
            width: 70px;
            transition: box-shadow 250ms ease;
            border: 1px solid #b3b3b3;

            &:focus {
              outline: 0;
              border-color: rgba(82, 168, 236, 0.8);
              box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
                0 0 8px rgb(82 168 236 / 60%);
            }
          }

          button {
            height: 35px;
            border: 1px solid #b3b3b3;
            text-align: center;
            width: 35px;
            margin: 0px 3px;
            cursor: pointer;
          }

          .button-left {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
          }

          .button-right {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
          }
        }

        .subtotal {
          @media (max-width: 640px) {
            display: none;
          }
        }

        .cart-remove-item {
          background-color: #f5f5f5;
          border: 1px solid rgb(0, 0, 0, 0.25);
          height: 30px;
          width: 30px;
          border-radius: 100%;
          cursor: pointer;
          position: relative;

          &:hover {
            background-color: #e6e6e6;
          }
        }
      }

      .btn {
        &:hover {
          background-color: #e6e6e6;
        }
      }

      .discount-coupon {
        border-radius: 25px;
        height: 35px;
        border: 1px solid rgba(0, 0, 0, 0.25);

        transition: box-shadow 250ms ease;

        &:focus {
          outline: 0;
          border-color: rgba(82, 168, 236, 0.8);
          box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
            0 0 8px rgb(82 168 236 / 60%);
        }
      }

      .discount-btn {
        display: flex;
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0.25);
        padding: 10px 15px;
        border-radius: 25px;
        font-size: 12px;
        cursor: pointer;

        i {
          margin-right: 3px;
        }
      }

      .cart-installments {
        &:hover {
          text-decoration: underline;
        }
        & a,
        i {
          color: var(--primary);
          text-decoration: none;
          margin-right: 5px;
        }
      }
    }

    .shopping-btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0;
      padding: 10px;

      button {
        width: 350px;
        height: 45px;
        border-radius: 25px;
        border: none;
        text-align: center;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 14px;
        text-transform: uppercase;

        @media (max-width: 640px) {
          margin: 0px 5px;
          font-size: 12px;
        }

        .fa {
          font-size: 12px;
        }

        .fa-chevron-right {
          margin-left: 5px;
        }

        .fa-chevron-left {
          margin-right: 5px;
        }
      }

      .btn-continue {
        border: 1px solid rgba(191, 0, 0, 1);
        background-color: rgba(216, 144, 22, 1);
        color: #ffffff;

        &:hover {
          background-color: rgba(222, 0, 0, 1);
        }
      }

      .btn-keep-shopping {
        border: 1px solid rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        color: #333;

        &:hover {
          background-color: #e6e6e6;
        }
      }
    }
  }
}

//TOOLTIP FOR REMOVE BTN
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;

  &:hover {
    .tiptext {
      visibility: visible;
      opacity: 1;
    }
  }

  &.top {
    .tiptext {
      margin-left: -30px;
      bottom: 120%;
      left: 50%;

      &::after {
        margin-left: -5px;
        top: 100%;
        left: 50%;
        border-color: #2e2e2e transparent transparent transparent;
      }
    }
  }
  .tiptext {
    visibility: hidden;
    opacity: 0;
    font-size: 10px;
    width: 60px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 6px 0;
    position: absolute;
    z-index: 1;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: opacity 500ms ease-in-out;

    &::after {
      content: "";
      position: absolute;
      border-width: 5px;
      border-style: solid;
    }
  }
}

//MODAL

.modal-container {
  background-color: white;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .modal-header {
    justify-content: stretch;

    button {
      position: absolute;
      right: -5px;
      top: -10px;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      border: 1px solid #b3b3b3;
      cursor: pointer;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%);
    }
  }
  .modalForm-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    select {
      border-radius: 20px;
      height: 45px;
      width: 340px;
      margin: 10px 0px;

      @media (max-width: 768px) {
        width: 300px;
        height: 35px;
        font-size: 16px;
      }
    }

    .modal-price-container {
      display: flex;

      div {
        margin: 22px;
        display: flex;
        flex-direction: column;
      }

      span {
        text-align: center;
      }

      span:nth-child(2) {
        font-size: 24px;
        margin-top: 10px;
        font-weight: bold;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }

    .modal-cards-logos {
      width: 400px;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 20px;

      @media (max-width: 768px) {
        width: 300px;
      }

      img {
        margin: 5px;
      }
    }

    .modal-close-container {
      padding: 10px;
      button {
        border-radius: 20px;
        padding: 10px 20px;
        border: 1px solid #b3b3b3;
        color: #333;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
