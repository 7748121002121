.errorInput-msg {
  background-color: #b94a48; //Custom Color in props
  color: white;
  border: 1px solid #b94a48;
  text-align: center;
  width: 312px; //Custom Width in props
  bottom: -35%;
  position: absolute;
  box-sizing: border-box;
  height: 35px;
  line-height: 35px;
}
