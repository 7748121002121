:root {
  --accent: rgba(191, 0, 0, 1);
  --primary: rgba(216, 144, 22, 1);
  --secondary: rgb(184, 130, 37);
  --borders: rgb(170, 168, 168);
  --background: rgba(255, 255, 255, 0.85);
  --borders: rgba(227, 227, 227, 1);
}

.form-container {
  background-color: rgba(255, 255, 255, 0.75);
  border: 0.5px solid var(--borders);
  padding: 15px 10px;
  width: 65%;
  margin: 25px auto;
  padding: 15px 25px;

  @media (max-width: 1024px) {
    width: 95%;
  }

  @media (max-width: 767px) {
    width: 80%;
  }

  .flex-wrapper {
    display: flex;
    flex-shrink: 1;

    @media (max-width: 640px) {
      flex-direction: column;
    }

    .address-container {
      margin-left: 25px;
      address {
        display: flex;
        flex-direction: column;

        a {
          color: var(--primary);
        }
      }
    }

    & div {
      margin-right: 20px;
      padding: 15px 0px;
      width: 100%;

      @media (max-width: 1024px) {
        width: 95%;
        font-size: 14px;
      }
    }

    & img {
      display: inline-block;
      width: 50%;
      height: 20%;
      margin: 0 auto;

      padding: 15px 0px;
    }
  }

  & textarea {
    width: 100%;
    border-radius: 15px;
    border: 0.5px solid var(--borders);
  }

  .suscribe-label {
    font-size: 14px;
    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }

  .suscribe-check {
    margin-right: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .form-button {
    width: 50%;
    padding: 10px 0px;
    border-radius: 15px;
    background-color: var(--primary);
    color: #fff;
    border: none;
    cursor: pointer;

    @media (max-width: 767px) {
      width: 100%;
    }
    &:hover {
      background-color: var(--accent);
    }
  }

  .form-disclaimer {
    font-size: 14px;
  }
}
