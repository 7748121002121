.tabs {
  list-style: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .tab-link {
    color: rgba(216, 144, 22, 1);
    text-decoration: none;
    margin-left: 5px;
    padding: 5px;
  }
  .tab-element {
    font-size: 14px;
    color: rgba(216, 144, 22, 1);
  }
}

.tabs > li {
  padding: 5px 15px;
  margin: 0px 5px;
  cursor: pointer;
  border-bottom: none;
  color: rgba(145, 125, 6, 1);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

.active-buy {
  background-color: rgba(191, 0, 0, 1);
  width: 25px;
  font-weight: lighter;
  border-radius: 20px;
  display: inline-block;
  text-align: center;
  color: white;
  font-size: 14px;
}

.current {
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  position: relative;

  .tab-link,
  i {
    color: #555;
    cursor: default;
  }
}

.content {
  padding: 20px;
  min-width: 450px;
  margin: 20px auto;
  width: 800px;
}
