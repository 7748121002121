.whatsapp-btn {
  border: 0 none;
  background-color: #29a71a;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  color: #ffffff !important;
  font-size: 30px;
  line-height: 48px;
  text-align: center;
  width: 50px;
  height: 50px;
  padding: 0px;
  border-radius: 100%;
  position: fixed;
  bottom: 15%;
  right: 1%;
  z-index: 100;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
  transition: box-shadow 200ms ease;
  cursor: pointer;
  &:hover {
    box-shadow: none;
  }
}

.whatsappbtn-modal {
  background-color: white;
  width: 500px;
  height: 100%;
  position: relative;

  .whatsappbtn-modal-header {
    background-color: #29a71a;
    padding: 9px 15px;

    i {
      text-align: center;
      display: block;
      color: white;
      font-size: 32px;
    }

    button {
      position: absolute;
      right: -5px;
      top: -10px;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      border: 1px solid #b3b3b3;
      cursor: pointer;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%);

      i {
        font-size: 14px;
        color: black;
      }
    }
  }

  form {
    padding: 15px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .whatsappbtn-textarea-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      label {
        display: inline-block;
        font-size: 14px;
      }

      textarea {
        display: block;
        width: 285px;
        height: 50px;
        border-radius: 25px;
        padding: 10px 15px;
        margin-top: 10px;
        border-color: #29a71a;

        &:focus {
          box-shadow: 0px 0px 5px var(--completedInput);
          outline: var(--completedInput);
        }
      }
    }
  }

  .whatsappbtn-btn-container {
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    padding: 15px 0px;
    text-align: center;
    button {
      width: 300px;
      margin: 0 auto;
      background-color: #29a71a;
      border-radius: 25px;
      color: white;
      border: none;
      height: 45px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.test {
  width: 300px;
}
