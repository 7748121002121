.dropdown-ul {
  position: absolute;
  background-color: rgb(255, 255, 255);
  top: 100%;
  left: 0;
  width: max-content;
  z-index: 1;
  padding: 0;
  border: 1px solid rgba(227, 227, 227, 1);
  box-shadow: 0 10px 10px rgb(0 0 0 / 30%);

  .dropdown-btn {
    display: contents;
    text-align: left;
    display: block;
  }
}
.dropdown-btn-submenu {
  left: 100%;
  top: 0;
}

.show {
  transition-property: visibility, opacity;
  transition-duration: 0s, 1s;
}

.hide {
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 0.4s, 0s;
  transition-delay: 0s, 0.9s;
}
