:root {
  --accent: rgba(191, 0, 0, 1);
  --primary: rgba(216, 144, 22, 1);
  --secondary: rgb(184, 130, 37);
  --borders: rgb(170, 168, 168);
  --background: rgba(255, 255, 255, 0.85);
  --borders: rgba(227, 227, 227, 1);
}

.input-style {
  border: 0.5px solid var(--borders);
  height: 30px;
  border-radius: 15px;
  width: 300px;

  &:focus {
    border-color: var(--secondary);
    box-shadow: 0px 0px 2px var(--accent);
    outline: none;
  }
}

.login-container {
  background-color: var(--background);
  width: 65%;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid var(--borders);

  @media (max-width: 640px) {
    width: 90%;
    padding: 10px;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;

    & label {
      margin-top: 10px;
    }

    & input {
      margin-right: 5px;
    }
  }

  & .login-button button {
    border: 1px solid var(--secondary);
    background-color: var(--primary);
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;

    &:hover {
      background-color: var(--accent);
      transition: background-color ease-in-out 200ms;
    }
  }
}

.email-container {
  display: flex;
  flex-direction: column;

  & input {
    margin-bottom: 15px;
  }
}

.password-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  position: relative;

  & input {
    border-radius: 15px;
    height: 35px;
    border: 1px solid var(--borders);
    margin-top: 5px;
  }

  .logincard-btn-password-show {
    border: none;
    background: none;
    padding: 0px 10px;
    cursor: pointer;
    position: absolute;
    left: 72%;
    top: 6%;
    width: 100px;
    color: var(--primary);
    font-size: 14px;

    &:hover {
      color: var(--accent);
      text-decoration: underline;
    }

    & i {
      margin-right: 5px;
    }
  }
}

.logincard-forgot-password {
  color: var(--primary);

  &:hover {
    color: var(--accent);
    text-decoration: underline;
  }
}
