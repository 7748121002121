.registration-container {
  background-color: rgba(255, 255, 255, 0.72);
  max-width: 900px;
  min-width: 750px;
  margin: 25px auto;
  padding: 5px 15px;

  .form {
    width: 475px;
    padding: 10px;

    & p {
      color: rgba(0, 0, 0, 0.7);
      opacity: 0.6;
      font-size: 12px;
    }

    .registration-wrapper {
      margin-bottom: 35px;
      .section-title {
        font-size: 14px;
        display: block;
      }
    }
  }
}

.user-adress-container {
  width: 500px;
}

.user-favourites-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    max-width: 100px;
  }

  div {
    width: 75%;
  }

  a {
    margin-bottom: 10px;
    color: rgba(216, 144, 22, 1);
    display: block;
  }

  p {
    margin: 5px 0;
    font-size: 12px;
  }
  button {
    height: 45px;
    border-radius: 100%;
    width: 45px;
    border: none;
    border: 1px solid white;
    background-color: #da4f49;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: #bd362f;
    }
  }
}

.registration-security-helper {
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.45);
}

.personal-data-container {
  position: relative;

  .personal-data-icon {
    position: absolute;
    top: 0;
    bottom: 200px;
  }
}

.registration-whatsapp {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.registration-phone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.registration-dates-container {
  & span {
    margin-bottom: 20px;
    display: block;
  }

  .registration-dates-options {
    display: flex;

    & label {
      display: flex;
      flex-direction: column;
    }

    & select {
      margin: 20px 20px 0 0;
      border-radius: 20px;
      width: 100px;
      height: 35px;
    }
  }
}

.form-button {
  width: 25%;
  padding: 10px 0px;
  border-radius: 15px;
  background-color: var(--primary);
  color: #fff;
  border: none;
  cursor: pointer;

  @media (max-width: 767px) {
    width: 100%;
  }
  &:hover {
    background-color: var(--accent);
  }
}

.table {
  table {
    border-collapse: collapse;
    border-spacing: 0;
    border: none;
    display: inline-block;
    table-layout: fixed;
    width: 100%;
    vertical-align: middle;
  }

  td,
  th {
    border-bottom: 1px solid rgba(153, 153, 153, 0.39);
    padding: 10px;
    margin: 0;
    text-align: left;
    overflow: hidden;
    font-size: 13px;
  }

  .btn-link {
    background-color: #5bb75b;
    padding: 10px 14px;
    cursor: pointer;
    border: 1px solid rgba(153, 153, 153, 0.39);
  }

  .fa-list {
    font-size: 15px;
    color: white;
  }
}
