.payment-container {
  background-color: var(--background);
  border: 1px solid var(--borders);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 50%;
  margin: 10px;

  @media (max-width: 768px) {
    width: 95%;
    margin: 10px auto;
  }

  .payment-title {
    padding: 10px;
    font-weight: bold;
    border-bottom: 1px solid var(--borders);
  }
  .payment-info-container {
    //This border activates when radio is selected?
    margin-bottom: 10px;
    padding: 10px;

    .payment-mercadopago-img {
      padding: 0px 20px;

      div {
      }

      img {
        margin-left: 5px;
        height: 15px;
      }

      span {
        display: block;
        margin-left: 4px;
        margin-top: 10px;
      }
    }
  }

  .payment-form {
    border-bottom: 1px solid var(--borders);
    p {
      font-weight: bold;
    }

    padding: 0 35px;
    .payment-form-wrapper {
      display: flex;
      align-items: center;
      margin: 10px 0px;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 15px;
      margin-bottom: 10px;
      text-align: left;

      @media (max-width: 768px) {
        width: 100%;
      }

      select {
        margin-top: 10px;
        width: 200px;
        border-radius: 25px;
        padding: 5px 0px;
        border: 1px solid var(--borders);

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      input {
        margin-top: 10px;
        width: 200px;
        padding: 5px 0px;
        border-radius: 25px;
        border: 1px solid var(--borders);

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }

  & > span {
    font-weight: bold;
    padding: 10px;
  }

  .payment-radio {
    padding: 10px 0px;

    input {
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .payment-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    button {
      height: 45px;
      border: 1px solid #b3b3b3;
      text-align: center;
      width: 100%;
      border-radius: 25px;
      margin: 0px 3px;
      cursor: pointer;
      font-size: 14px;
    }

    .shiptype-left-btn {
      background-color: #f5f5f5;
      i {
        margin-right: 5px;
      }
      &:hover {
        background-color: #e6e6e6;
      }
    }
    .shiptype-right-btn {
      background-color: rgba(216, 144, 22, 1);
      color: #fff;
      i {
        margin-left: 5px;
      }
      &:hover {
        background-color: rgba(222, 0, 0, 1);
      }
    }
  }
}
