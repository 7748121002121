.item-a-subdrawer {
  padding: 15px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.335);
}

.subdrawer-container {
  position: absolute;
  bottom: 0;
  height: 100%;
  transition: transform 400ms ease;
  width: 95%;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(51, 51, 51, 0.335);
  z-index: 2;
}

.subdrawer-back-button {
  background-color: rgb(165, 165, 165);

  padding: 21px 20px;
  font-size: 18px;
}

.subdrawer-back-button span::after {
  position: absolute;
  top: 28px;
  content: "";
  display: inline-block;
  right: 10%;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(226deg);
  margin-right: 0.5em;
  cursor: pointer;
}

.subdrawer-container.subdrawer-on {
  transform: translateX(0);
}

.subdrawer-container.subdrawer-off {
  transform: translateX(-100%);
}
