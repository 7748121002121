//!Update this with grid to form 4 columns layout.

.gallery-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 90%;
  align-items: start;
  margin: 0 auto;
  justify-items: stretch;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
