.navbarbottom {
  padding: 0px 20px;
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    display: none;
  }

  .navbarbottom-ul {
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
