.error {
  padding: 20px;
  background-color: #f2dede;
  border: 1px solid #b94a48;
  color: #b94a48;
  width: 65%;
  margin: 0 auto;

  @media (max-width: 640px) {
    width: 90%;
    padding: 10px;
  }

  i,
  a {
    color: #b94a48;
    margin-right: 5px;
  }
}

.warning {
  padding: 20px;
  background-color: #ffe9a279;
  border: 1px solid #ffcc00;
  color: #ffcc00;
  width: 65%;
  margin: 0 auto;

  @media (max-width: 640px) {
    width: 90%;
    padding: 10px;
  }

  i,
  a {
    color: #ffcc00;
    margin-right: 5px;
  }
}

.success {
  padding: 20px;
  background-color: #bcffb786;
  border: 1px solid #4bb543;
  color: #4bb543;
  width: 65%;
  margin: 0 auto;

  @media (max-width: 640px) {
    width: 90%;
    padding: 10px;
  }

  i,
  a {
    color: #4bb543;
    margin-right: 5px;
  }
}
