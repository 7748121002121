.footer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  background-color: rgba(239, 194, 78, 1);
  color: white;
  font-size: 14px;
  padding: 25px;
  margin-top: 200px;

  @media (max-width: 640px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px;
  }
}

.footer a {
  color: white;

  &:hover {
    color: rgba(224, 224, 224, 1);
  }
}

.footer-contact {
  margin-top: 20px;

  span {
    display: inline-block;
  }
}

.suscribe {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.footer-facebook {
  border: none;
  visibility: visible;
}

.footer-intro {
  padding-left: 2px;
  padding-right: 9px;
}

.footer-provincia {
  visibility: hidden;
}

.footer-list {
  li {
    i {
      padding-right: 10px;
    }
  }
}

.footer-mail-input {
  margin: 10px 0px;
  color: rgba(43, 43, 43, 1);
  border: 1px solid rgba(168, 168, 168, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 25px;
  height: 20px;
  padding: 4px 6px;
  width: 200px;
  transition: box-shadow 250ms ease;

  &:focus {
    outline: 0;
    border-color: rgba(82, 168, 236, 0.8);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(82 168 236 / 60%);
  }
}

.footer-mail-input-button {
  border-radius: 25px;
  background-color: #f5f5f5;
  font-size: 15px;
  cursor: pointer;
  color: #333333;
  border: 1px solid #cccccc;

  &:hover {
    background-color: #e6e6e6;
  }
}
